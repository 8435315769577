import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    marginBottom: 20,
  },
  institution: {
    fontFamily: 'Lato Bold',
    fontSize: 12,
  },
  degree: {
    fontFamily: 'Lato Italic',
    fontSize: 10,
    marginBottom: 5,
  },
  description: {
    fontSize: 10,
    fontFamily: 'Lato',
  },
});

const EducationEntry = ({ institution, degree, years, description }) => (
  <View style={styles.container}>
    <Text style={styles.institution}>{institution}</Text>
    <Text style={styles.degree}>{degree} ({years})</Text>
    <Text style={styles.description}>{description}</Text>
  </View>
);

const Education = ({ data }) => (
  <View>
    {data.map((edu, index) => (
      <EducationEntry
        key={index}
        institution={edu.institution}
        degree={edu.degree}
        years={edu.years}
        description={edu.description}
      />
    ))}
  </View>
);

export default Education;
