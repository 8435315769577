import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    marginBottom: 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  skillColumn: {
    width: '33%',
    padding: 5,
  },
  skillText: {
    fontSize: 10,
    fontFamily: 'Lato',
  },
});

const Skills = ({ data }) => (
  <View style={styles.container}>
    {data.map((skill, index) => (
      <View key={index} style={styles.skillColumn}>
        <Text style={styles.skillText}>{skill.title}</Text>
      </View>
    ))}
  </View>
);

export default Skills;
