import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
    paddingBottom: 5,
    borderBottomWidth: 1,
    borderColor: '#cccccc',
  },
  name: {
    fontSize: 24,
    fontFamily: 'Lato Bold',
  },
  title: {
    fontSize: 12,
    fontFamily: 'Lato Italic',
    color: '#666666',
  },
  contactInfo: {
    fontSize: 10,
    textAlign: 'right',
  },
});

const Header = ({ data }) => (
  <View style={styles.container}>
    <View>
      <Text style={styles.name}>{data.name}</Text>
      <Text style={styles.title}>{data.profile}</Text>
    </View>
    <View>
      <Text style={styles.contactInfo}>e: {data.email}</Text>
      <Text style={styles.contactInfo}>w: {data.website}</Text>
      <Text style={styles.contactInfo}>m: {data.phone}</Text>
    </View>
  </View>
);

export default Header;
