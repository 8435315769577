import React, { useContext, useEffect, useState } from "react";
import { Accordion, ListGroup, Spinner, Modal, Form } from "react-bootstrap";
import { Link, useNavigate,useLocation } from "react-router-dom";
import axios from "axios";
import Helpers from "../../../Config/Helpers";
import { createClient } from "@supabase/supabase-js";
import { RecursiveCharacterTextSplitter } from "langchain/text_splitter";
import { SupabaseVectorStore } from "@langchain/community/vectorstores/supabase";
import { OpenAIEmbeddings } from "@langchain/openai";
import { ChatAppContext } from "../../../Context/AppContext";
import { useFolders } from "../../../Context/FolderContext";
import PlanCard from "../../../Components/PlanCard";
import { usePlan } from "../../../Context/PlanContext";
import { useApi } from "../../../Context/ApiContext";
import ChatModal from "../../../Components/ChatModal";
import { useChatsContext } from "../../../Context/ChatsContext";



const SideBarRedesigned = () => {
  const { apiSettings } = useApi();
  const location = useLocation();
  const sbUrl = apiSettings.supabaseUrl;
  const sbApiKey = apiSettings.supabaseApiKey;
  const { newChat, selectedDocs, setSelectedDocs } = useContext(ChatAppContext);
  const navigate = useNavigate();
  const [isUserDataLoading, setIsUserDataLoading] = useState(true);
  const { isPlanActive, TokensAvailable, userData, getProfileInfo } = usePlan();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [noPermission, setNoPermission] = useState();
  // const [userChats, setUserChats] = useState([]);
  const {folders,setFolders,deleteFolder,getFolders} = useFolders();
  const [UserData, setUserData] = useState({});
  const {selectedChat,setSelectedChat, userChats, setUserChats,getPreviousChats} = useChatsContext();
  const [selectedChatFiles, setSelectedChatFiles] = useState([]);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [isChatAccordionOpen, setIsChatAccordionOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeChatId, setActiveChatId] = useState(null);
  const [activeFolderId, setActiveFolderId] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // const [folders, setFolders] = useState([]); // State for folders
  const [showFolderModal, setShowFolderModal] = useState(false); // Modal state for folder creation
  const [folderName, setFolderName] = useState("");
  useEffect(() => {

    getFolders(); 
  }, []);

  const [activeParentKey, setActiveParentKey] = useState("0");

  // State to control the active key for each nested accordion (files inside folders)
  const [activeNestedKeys, setActiveNestedKeys] = useState({});

  // Handler for parent accordion change
  const handleParentAccordionChange = (key) => {
    setActiveParentKey(key); // Control the active key for parent accordion
  };

  // Handler for nested accordion change
  const handleNestedAccordionChange = (index, key) => {
    setActiveNestedKeys((prevState) => ({
      ...prevState,
      [index]: key, // Control the active key for each folder's nested accordion
    }));
  };

  const createFolder = async () => {
    if (!folderName) return;
    try {
      const response = await axios.post(
        `${Helpers.apiUrl}folders`,
        { name: folderName },
        Helpers.authHeaders
      );
      setFolders((prev) => [...prev, response.data]);
      setFolderName(""); // Reset folder name
      setShowFolderModal(false); // Close modal
      Helpers.toast("success", "Folder created successfully!");
      getFolders();
    } catch (error) {
      console.error("Error creating folder:", error);
      Helpers.toast("error", "Failed to create folder.");
    }
  };

  // const deleteFolder = async (folderId) => {
  //   try {
  //     await axios.delete(
  //       `${Helpers.apiUrl}folders/${folderId}`,
  //       Helpers.authHeaders
  //     );
  //     setFolders((prev) => prev.filter((folder) => folder.id !== folderId));
  //     Helpers.toast("success", "Folder deleted successfully!");
  //   } catch (error) {
  //     console.error("Error deleting folder:", error);
  //     Helpers.toast("error", "Failed to delete folder.");
  //   }
  // };
  const logout = (e) => {
    e.preventDefault();
    Helpers.toast("success", "Logged out successfully");
    Helpers.authUser = null;
    localStorage.clear();
    navigate("/");
  };

  const estimateTokensForVectorization = (text) => {
    const tokens = text.split(/\s+/).length;
    return tokens;
  };

  const handleResize = () => {
    const width = window.innerWidth;
    if (width <= 768) {
      setIsCollapsed(true);
    } else {
      setIsCollapsed(false);
    }
  };
  useEffect(() => {
    if (userData) {
      if (userData.permissions === 1) {
        setNoPermission(true);
      } else {
        setNoPermission(false);
      }
      setIsUserDataLoading(false);
    }
  }, [userData]);
  useEffect(() => {
    const savedState = localStorage.getItem("sidebarCollapsed") === "true";
    setIsCollapsed(savedState);

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
  }, [isCollapsed]);

  const toggleCollapse = () => {
    setIsCollapsed((prevState) => {
      const newState = !prevState;
      localStorage.setItem("sidebarCollapsed", newState);
      return newState;
    });
  };

  const NavigateToChatPage = (chats,index) => {
    setSelectedChat(chats.title);
    setSelectedChatFiles(chats.file);
    setActiveChatId(chats.chatid);
    setActiveFolderId(chats.folder_id);
    setIsAccordionOpen(false);
    navigate(`/chat/chatbot/${chats.chatid}`);
  };

  useEffect(() => {
    // Extract chat_id from the URL (e.g., /chat/chatbot/:chat_id)
    const chatIdFromUrl = location.pathname.split('/').pop();

    // Check if we have a valid chat_id in the URL
    if (chatIdFromUrl) {
      // Find the chat with the matching chat_id
      const chat = userChats.find(chat => chat.chatid === chatIdFromUrl);

      if (chat) {
        console.log("My New Chat", chat);
        // If chat exists, set the state just like you would on click
        setSelectedChat(chat.title); // Assuming you want the first file's name
        setSelectedChatFiles(chat.file);
        setActiveChatId(chat.chatid);
        setActiveFolderId(chat.folder_id);
        setIsAccordionOpen(false);
      }
    }
  }, [location.pathname, userChats]);


  useEffect(() => {
    getPreviousChats(); // Fetch previous chats if user data exists
  }, []);

  // useEffect(() => {
  //   if (newChat) {
  //     console.log("My New Chat", newChat);
  //     setUserChats((prevChats) => [...prevChats, newChat]);
  //     setSelectedChat(newChat.chat_detail);
  //     setSelectedChatFiles(newChat.file);
  //     setActiveChatId(newChat.chat_id);
  //     setActiveFolderId(newChat.folder_id);
  //   }
  // }, [newChat]);

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };
  const toggleChatAccordion = () => {
    setIsChatAccordionOpen(!isChatAccordionOpen);
  };

  const handleUpload = async (file, chatid,folder_Id) => {
    console.log(folder_Id)
    if (file && chatid) {
      setIsLoading(true);
      try {
        const formData = new FormData();
        formData.append("files[]", file);
        formData.append("chatid", chatid);
        formData.append("prompt_id", 1);
        formData.append("folderId", folder_Id);

        const response = await axios.post(
          `${Helpers.apiUrl}user/save`,
          formData,
          Helpers.authFileHeaders
        );


        if (response) {
          setSelectedChatFiles([file.name]);
          await getPreviousChats();

          const chatData = response.data.file_content;
          console.log(chatData)
          const splitter = new RecursiveCharacterTextSplitter({
            chunkSize: 500,
            chunkOverlap: 50,
            separators: ["\n\n", "\n", " ", ""],
          });
          const output = await splitter.createDocuments([chatData]);

          let totalTokensForVectorization = 0;
          output.forEach((chunk) => {
            totalTokensForVectorization += estimateTokensForVectorization(
              chunk.pageContent,
            );
            chunk.metadata = { ...chunk.metadata, contentid: response.data.content_id };
          });


          const client = createClient(sbUrl, sbApiKey);
          await Promise.all([
            SupabaseVectorStore.fromDocuments(
              output,
              new OpenAIEmbeddings({
                openAIApiKey: apiSettings.openAiApiKey,
              }),
              {
                client,
                tableName: "docsphere_documents",
              }
            ),
            axios.post(
              `${Helpers.apiUrl}updateTokens`,
              { tokens: totalTokensForVectorization },
              Helpers.authHeaders
            ),
          ]);
          Helpers.toast(
            "success",
            "File uploaded successfully to the existing chat"
          );
          await getProfileInfo();
          console.log("Vectorization complete");
        }
      } catch (error) {
        if (error.response) {
          console.log("error", error.response.data.message);
          Helpers.toast("error", error.response.data.message);
        } else {
          Helpers.toast("error", error);
          console.log("error", error);
        }
      } finally {
        const { data } = await axios.get(`${Helpers.apiUrl}chat/get/${activeChatId}`, Helpers.authHeaders);
        await getProfileInfo();
        console.log("Fetched chat data:", data);
        if (data.content_ids) {
          setSelectedDocs(data.content_ids);
        } else {
          setSelectedDocs([]);
        }
        setIsLoading(false);
      }
    } else {
      Helpers.toast("error", "Can't send without file or chatId");
    }
  };

  const handleFileChange = (e) => {
    if (!TokensAvailable) {
      Helpers.toast("error", "Your Tokens Are Almost Finished");
      return;
    }

    const file = e.target.files[0];
    if (file) {
      setSelectedChatFiles([]);
      handleUpload(file, activeChatId,activeFolderId);
    }
  };

  return (
    <div
      className={`h-[100vh] flex
 scrollbar-thin flex-column position-relative`}
     style={{  minWidth: isCollapsed ?'0px' :'20vw'}}>
      <ChatModal
        handleShow={handleShow}
        handleClose={handleClose}
        show={show}
      />
      <Modal show={showFolderModal} onHide={() => setShowFolderModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create Folder</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="folderName">
              <Form.Label>Folder Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter folder name"
                value={folderName}
                onChange={(e) => setFolderName(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => setShowFolderModal(false)}
          >
            Close
          </button>
          <button className="btn btn-primary" onClick={() => createFolder(folderName)}>
            Create Folder
          </button>
        </Modal.Footer>
      </Modal>
      {/* <div className="flex-grow-2 mb-12 h-auto"> */}

      <div className="flex-grow-2 mb-12 h-auto">
        <div
          className={`flex items-center  justify-between py-2 ${
            isCollapsed ? "flex-col items-center" : "flex-row"
          }`}
        >
          <div className={`flex-shrink-0 ${isCollapsed ? "" : "py-2"}`}>
            <Link to="/user/profile">
              <img
                className={`rounded-full ${
                  isCollapsed ? "h-8 w-8" : "h-10 w-10"
                }`}
                src={Helpers.serverImage(Helpers.authUser.profile_pic)}
                alt="author avatar"
              />
            </Link>
          </div>
          {!isCollapsed && (
            <div className="info-container flex-1 pl-2 m-0">
              <div className="text-xs font-semibold text-black">
                {Helpers.authUser.name}
              </div>
              <div className="text-xs">{Helpers.authUser.email}</div>
            </div>
          )}

          <div
            className={`flex-shrink-0 cursor-pointer ${
              isCollapsed ? "mt-2  btn btn-dark sm:!btn-primary" : ""
            }`}
            onClick={logout}
          >
            <i className="fal fa-power-off"></i>
          </div>
        </div>
        <div className="scrollable-container"

        >
        {/* Folders List */}
        {!isCollapsed && (
          <div>
            <h6 className="text-left p-2 font-semibold">Your Folders</h6>
            {/* {folders.length === 0 ? (
    <p>No folders.</p>
  ) : ( */}
 <Accordion activeKey={activeParentKey} onSelect={handleParentAccordionChange}>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Folders</Accordion.Header>
        <Accordion.Body style={{ maxWidth: '20vw' }}>
          {folders.map((folder, index) => (
            <Accordion
              key={folder.id}
              activeKey={activeNestedKeys[index]} // Dynamically control the active key for each folder's files
              onSelect={(key) => handleNestedAccordionChange(index, key)} // Update the active key for nested accordion
              style={{margin:"5px 0px"}}
            >
              <Accordion.Item eventKey={index.toString()}>
                <Accordion.Header className="w-full d-flex align-items-center justify-between">
                  <span>{folder.name}</span>
                  <button
                    className="btn btn-danger btn-sm ms-2 ml-auto"
                    onClick={() => deleteFolder(folder.id)} // Delete folder action
                  >
                    X
                  </button>
                </Accordion.Header>
                <Accordion.Body style={{ maxWidth: '18vw' }}>
                  {/* List files inside the folder */}
                  {folder.files && folder.files.length > 0 ? (
                    <ListGroup variant="flush">
                      {folder.files.map((file, fileIndex) => (
                        <ListGroup.Item
                          key={fileIndex}
                          className="text-truncate"
                        >
                          {file.name}
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  ) : (
                    <p>No files</p>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ))}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
            {/* )} */}
          </div>
        )}
            <div className="flex items-center mt-1 mb-3 justify-between">
              <button
                className={`btn btn-dark ${
                  isCollapsed ? "text-center w-auto" : "w-100"
                }`}
                onClick={() => setShowFolderModal(true)}
              >
                {isCollapsed ? (
                  <i className="fal fa-folder"></i>
                ) : (
                  <div className="flex gap-1">
                    <span>
                      <i className="fal fa-folder"></i>
                    </span>
                    <span className="d-inline">New Folder</span>
                  </div>
                )}
              </button>
            </div>
        {!isCollapsed && (
          <div>
            <div className="">
              <h6 className="text-left p-2 font-semibold">Your Chats</h6>
              <Accordion
                
                activeKey={isAccordionOpen ? "0" : null}
              >
                <Accordion.Item eventKey="0">
                  <Accordion.Header
                    className="truncate"
                    onClick={toggleAccordion}
                  >
                     {selectedChat.slice(0,25)}
                   
                  </Accordion.Header>
                  <Accordion.Body
                    className="scrollbar-thumb-[#160647] scrollbar-track-[#ECEFF1]
 scrollbar-thin"
                    style={{
                      // maxWidth: "250px",
                      maxWidth:'18vw',
                      overflowY: "auto",
                    }}
                  >
                    {userChats.map((chats, index) => (
                      <ListGroup.Item key={index} className="fit-content">
                        <div
                          onClick={() => NavigateToChatPage(chats,index)}
                          className="d-flex align-items-center py-1 mt-1 cursor-pointer text-small hover:bg-secondary transition-all rounded-3 "
                        >
                          <div className="text-truncate ">
                          {chats?.title}
                          </div>
                        </div>
                      </ListGroup.Item>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            {/* <div className="w-full h-0.5 bg-gray-300 rounded-lg"></div> */}
          </div>
        )}
        <div
          className={`flex items-center mt-1 mb-3 justify-between ${
            isCollapsed ? "flex-col items-center" : ""
          }`}
        >
          <a
            className={`${isCollapsed ? " " : "w-100"} ${
              isPlanActive === "Expired" || noPermission
                ? "pointer-events-none"
                : ""
            }`}
            onClick={() => setShow(true)}
          >
            <button
              className={`btn btn-dark ${
                isCollapsed ? "text-center w-auto py-1" : "w-100"
              }`}
              type="button"
              aria-haspopup="dialog"
              aria-expanded="false"
              aria-controls="radix-:r0:"
              data-state="closed"
              disabled={isPlanActive === "Expired" || noPermission}
            >
              {isCollapsed ? (
                <span>
                  <i className="fal fa-plus"></i>
                </span>
              ) : (
                <div className="flex gap-1">
                  <span>
                    <i className="fal fa-plus"></i>
                  </span>
                  <span className="d-inline">New Chat</span>
                </div>
              )}
            </button>
          </a>
        </div>

        <div className="mt-0">
          {!isCollapsed && (
            <div>
            <div className=" pb-2">
              <h6 className="text-left p-2 font-semibold">Uploaded Files</h6>
              <Accordion
                
                activeKey={isChatAccordionOpen ? "1" : null}
              >
                <Accordion.Item eventKey="1">
                  <Accordion.Header
                    className="truncate w-full"
                    onClick={toggleChatAccordion}
                  >
                    Uploaded Files
                  </Accordion.Header>
                  <Accordion.Body
                    className="scrollbar-thumb-[#160647] scrollbar-track-[#ECEFF1] scrollbar-thin"
                    style={{
                      // maxWidth: "250px",
                      maxWidth:'18vw',
                      overflowY: "auto",
                    }}
                  >
                    {Helpers.authUser.files?.length > 0 ? (
                      Helpers.authUser.files.map((file, index) => (
                        <ListGroup.Item
                          key={index}
                          className="d-flex justify-content-between align-items-center"
                        >
                          <span className="text-truncate text-black py-1">{`${
                            index + 1
                          } : ${file.name}`}</span>
                        </ListGroup.Item>
                      ))
                    ) : (
                      <p>No uploaded files.</p>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          <div
            className={`position-relative flex justify-evenly ${
              isPlanActive === "Expired" || noPermission
                ? "pointer-events-none opacity-50"
                : ""
            }`}
          >
            <input
              type="file"
              id="file-upload"
              className="form-control d-none"
              onChange={handleFileChange}
              disabled={isLoading || !activeChatId || noPermission}
            />

            <label
              htmlFor="file-upload"
              className={`btn btn-dark ${
                isCollapsed ? "text-center w-auto" : "w-100"
              }`}
            >
              {isLoading ? (
                <>
                  <Spinner animation="border" size="sm" className="me-2" />
                  Uploading...
                </>
              ) : isCollapsed ? (
                <i className="fal fa-file-upload"></i>
              ) : (
                "Choose File"
              )}
            </label>
          </div>
          </div>
          )}
        </div>
        <div className="mx-auto mb-4">
          {userData &&
            !isCollapsed &&
            ((userData.is_org_owner && userData.org_id != null) ||
              (!userData.is_org_owner && userData.org_id == null)) && (
              <PlanCard />
            )}
        </div>
        </div>
        <div className={`mt-auto absolute bottom-10 w-100 ${isCollapsed ? '' : ' mt-2'} flex justify-evenly`}>
          <button className="mx-auto btn btn-dark" onClick={toggleCollapse}>
            {isCollapsed ? (
              <i className="fal fa-expand-arrows-alt"></i>
            ) : (
              <div>
                <i className="fal fa-compress-arrows-alt"></i>{" "}
                <span>Collapse</span>
              </div>
            )}
          </button>
        </div>
      </div>
        <style jsx>{`
        .scrollable-container {
  overflow-y: scroll; /* Enable vertical scrolling */
  height: calc(70vh - 10px); /* Ensure it respects your layout */
}

/* Hide the scrollbar */
.scrollable-container::-webkit-scrollbar {
  width: 0; /* Remove scrollbar width */
  height: 0; /* Remove scrollbar height */
}

.scrollable-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
        `}</style>
    </div>
    // </div>
  );
};

export default SideBarRedesigned;
