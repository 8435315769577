import React, { useEffect, useState, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import PageLoader from "./Loader/PageLoader";
import { useNavigate } from "react-router-dom";
import { ChatAppContext } from "../Context/AppContext";
import Helpers from "../Config/Helpers";
import { RecursiveCharacterTextSplitter } from "langchain/text_splitter";
import { createClient } from "@supabase/supabase-js";
import { SupabaseVectorStore } from "@langchain/community/vectorstores/supabase";
import { OpenAIEmbeddings, ChatOpenAI } from "@langchain/openai";
import { HumanMessage } from "@langchain/core/messages";
import { usePlan } from "../Context/PlanContext";
import { useApi } from "../Context/ApiContext";
import { useChatsContext } from "../Context/ChatsContext";
import { useFolders } from "../Context/FolderContext";

export default function ChatModal({ handleClose, show }) {
  const { setNewChat, selectedDocs, setSelectedDocs, setChatFile } =
    useContext(ChatAppContext);
  const [loader, setLoader] = useState(false);
  const [inputDocs,setInputDocs] = useState([]);
  const [fromPreviousFiles, setFromPreviousFiles] = useState(false);
  const [useCase, setUseCase] = useState("");
  const [useCases, setUseCases] = useState([]);
  const {folders,showFolders,setShowFolders, selectedFolder ,setSelectedFolder,getFolders} = useFolders();
  const [prompts, setPrompts] = useState([]);
  const {getPreviousChats} = useChatsContext();
  const { apiSettings } = useApi();
  const { TokensAvailable, userData, isPlanActive, getProfileInfo } = usePlan();
  const [dragging, setDragging] = useState(false);
  const [selectedPrompt, setSelectedPrompt] = useState("");
  const [customPrompt, setCustomPrompt] = useState("");
  const [useCustomPrompt, setUseCustomPrompt] = useState(false);
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  // const [folders, setFolders] = useState([]);
  // const [showFolders, setShowFolders] = useState(false);
  // const [selectedFolder, setSelectedFolder] = useState(null);

  useEffect(() => {
    async function fetchUseCases() {
      try {
        const response = await axios.get(
          `${Helpers.apiUrl}category/all`,
          Helpers.authHeaders
        );
        const useCases = response.data.map((useCase) => ({
          name: useCase.name,
          prompts: useCase.prompts.map((prompt) => ({
            id: prompt.id,
            text: prompt.prompt,
          })),
        }));
        setUseCases(useCases);
        if (useCases.length > 0) {
          setUseCase(useCases[0].name);
          setPrompts(useCases[0].prompts);
          setSelectedPrompt(useCases[0].prompts[0]);
        }
      } catch (error) {
        console.error("Error fetching use cases:", error);
        Helpers.toast("error", "Unable to fetch use cases.");
      }
    }

    fetchUseCases();
    console.log(prompts)
    console.log(folders)
    // getFoldersModal();
  }, []);

  // const getFolders = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${Helpers.apiUrl}folders`,
  //       Helpers.authHeaders
  //     );
  //     if (response.data && response.data.length > 0) {
  //       setFolders(response.data);
  //       setSelectedFolder(response.data[0].id);
  //     } else {

  //     }
  //   } catch (error) {
  //     console.error("Error fetching folders:", error);
  //   }
  // };

  const handlePromptChange = (e) => {
    const prompt = prompts.find(
      (prompt) => prompt.id === parseInt(e.target.value)
    );
    setSelectedPrompt(prompt);
  };
  const handleFolderChange = (e) => {
    setSelectedFolder(e.target.value);
  };

  const handleUseCaseChange = (e) => {
    const caseName = e.target.value;
    setUseCase(caseName);
    const selected = useCases.find((useCase) => useCase.name === caseName);
    if (selected) {
      setPrompts(selected.prompts);
      setSelectedPrompt(selected.prompts[0]);
    }
  };

  const performVectorization = async (chatData, contentId) => {
    if (!contentId) {
      console.error("Content ID is missing or undefined:", contentId);
      return;
    }

    const splitter = new RecursiveCharacterTextSplitter({
      chunkSize: 500,
      chunkOverlap: 50,
      separators: ["\n\n", "\n", " ", ""],
    });

    const output = await splitter.createDocuments([chatData]);
    let tokenCount = 0;

    output.forEach((chunk) => {
      tokenCount += chunk.pageContent.split(/\s+/).length;
      chunk.metadata = { ...chunk.metadata, contentid: contentId };
    });

    const client = createClient(
      apiSettings.supabaseUrl,
      apiSettings.supabaseApiKey
    );
    try {
      await SupabaseVectorStore.fromDocuments(
        output,
        new OpenAIEmbeddings({ openAIApiKey: apiSettings.openAiApiKey }),
        { client, tableName: "docsphere_documents" }
      );
      console.log("Data successfully vectorized and sent to Supabase");
    } catch (error) {
      console.error("Error inserting data into Supabase:", error);
    }

    return tokenCount;
  };

  const generateBriefContent = async (chatData, vectorTokenCount) => {
    const model = new ChatOpenAI({
      openAIApiKey: apiSettings.openAiApiKey,
      model: apiSettings.openAiApiModel,
    });

    const promptToUse = useCustomPrompt ? customPrompt : selectedPrompt.text;

    const summary = await model.invoke([
      new HumanMessage({
        content: `Generate a response based on the selected use case and prompt.
        Use case: **${useCase}**, prompt: **"${promptToUse}"**.
        Data: ${chatData}`,
      }),
    ]);

    const totalTokens =
      summary.response_metadata.tokenUsage.totalTokens + vectorTokenCount;
    await axios.post(
      `${Helpers.apiUrl}updateTokens`,
      { tokens: totalTokens },
      Helpers.authHeaders
    );
    await getProfileInfo();

    return summary.content;
  };
  
  const handleUpload = async (uploadedFiles) => {
    let mergedContent = "";
    let chatId = null; // Initialize chatId
    let vectorTokenCount = 0;
  
    try {
      console.log("Starting file upload...");
      console.log("Uploaded files:", uploadedFiles);
  
      const contentIds = [];
  
      console.log("Processing files...");
      for (const file of uploadedFiles) {
        try {
          console.log("Processing file:", file.name);
  
          const formData = new FormData();
          formData.append("files[]", file);
          formData.append("instructions", customPrompt);
          formData.append("prompt_id", selectedPrompt?.id);
          formData.append("folderId", selectedFolder);
  
          // Reuse the same chatId for subsequent files
          if (chatId) {
            formData.append("chatid", chatId); // Attach existing chatId
          }
  
          console.log("Sending file to API...");
          const response = await axios.post(
            `${Helpers.apiUrl}user/save`,
            formData,
            Helpers.authFileHeaders
          );
  
          console.log("File upload response:", response.data);
  
          // Capture chatId for the first file
          if (!chatId) {
            chatId = response.data.chat_id;
          }
  
          const contentId = response.data.content_id;
          const chatContent = response.data.file_content;
  
          contentIds.push(contentId);
  
          console.log("Chat ID:", chatId);
          console.log("Content ID:", contentId);
  
          mergedContent += `\n${chatContent}\n`;
  
          console.log("Performing vectorization...");
          const tokens = await performVectorization(chatContent, contentId);
          vectorTokenCount += tokens;
  
          console.log("Vectorization complete. Tokens added:", tokens);
        } catch (fileError) {
          console.error("Error processing file:", file.name, fileError);
          Helpers.toast("error", `Failed to upload file: ${file.name}`);
        }
      }
  
      console.log("All files processed. Updating state...");
      setFiles([]);
      setSelectedDocs(contentIds);
  
      console.log("Fetching profile info...");
      await getProfileInfo();
  
      console.log("Generating brief content...");
      const briefContent = await generateBriefContent(
        mergedContent,
        vectorTokenCount
      );
  
      console.log("Sending brief content to bot...");
      const data = {
        input: briefContent,
        user_id: Helpers.authUser.id,
        chatid: chatId,
      };
  
      await axios.post(
        `${Helpers.apiUrl}bot/firstmessage`,
        data,
        Helpers.authHeaders
      );
  
      console.log("Preparing new chat object...");
      const newChat = {
        chat_detail: mergedContent.replace(/\n/g, " ").slice(0, 40),
        chat_id: chatId,
        file: Array.from(uploadedFiles).map((file) => file.name),
      };
      setNewChat(newChat);
  
      console.log("File upload and vectorization successful.");
      Helpers.toast(
        "success",
        "Files uploaded and content vectorized successfully"
      );
  
      return chatId;
    } catch (error) {
      console.error("Error during file upload process:", error);
      Helpers.toast("error", "File upload failed.");
    } finally {
      setLoader(false); // Hide loader after process completion
    }
  };
  

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]); // Append selected files to existing list
    e.target.value = ""; // Reset the file input to allow re-selection of the same file

  };

useEffect(() => {
  console.log(files)
  console.log(selectedFolder)
  console.log(selectedPrompt)
}, [files,selectedFolder,selectedPrompt])  

  //   const removeFile = (index) => {
  //     setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  //   };
  const clearFiles = () => {
    setFiles([]); // Reset files to an empty array
  };

  const handlePreviousDocsSelect = async (inputDocs) => {
    
    const fileIds = inputDocs.map((doc) => doc.id);
    const fileNames = inputDocs.map((doc) => doc.name);
    const fileContents = inputDocs.map((doc) => doc.file_content);
    const requestParams = {
      file_ids: fileIds,
      file_names: fileNames,
      instructions: customPrompt,
      prompt_id: selectedPrompt?.id,
      folderId: null,
    };
    console.log(requestParams);
    console.log(fileContents);
    setLoader(true);
    try {
      const response = await axios.post(
        `${Helpers.apiUrl}user/chatSession`,
        requestParams,
        Helpers.authHeaders
      );
      console.log(response.data)
      if (response.status === 200) {
        const newChat = {
          chat_detail: response.data.chat_title,
          chat_id: response.data.chat_id,
          folder_id: response.data.folder_id,
          // files: fileNames,
        };
        const mergedContent = fileContents;
        const briefContent = await generateBriefContent(
          mergedContent,
          0
        );
    
        console.log("Sending brief content to bot...");
        const data = {
          input: briefContent,
          user_id: Helpers.authUser.id,
          chatid: response.data.chat_id,
        };
        console.log(data);
        await axios.post(
          `${Helpers.apiUrl}bot/firstmessage`,
          data,
          Helpers.authHeaders
        );

        setNewChat(newChat);
        setInputDocs([]);
        setLoader(false);
        handleClose();
        navigate(`/chat/chatbot/${response.data.chat_id}`);
      }
    } catch (error) {
      setSelectedDocs([])
      console.error("Error starting chat with selected docs:", error);
      Helpers.toast("error", "Failed to start chat with selected documents.");
      setLoader(false);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const droppedFiles = Array.from(e.dataTransfer.files);
    setFiles(droppedFiles); // Replace old files with new drop
  };

  const handleUploadButton = async () => {
    if (
      !files.length ||
      userData.permissions === 1 ||
      !TokensAvailable ||
      isPlanActive === "Expired"
    ) {
      Helpers.toast(
        "error",
        "Check your permissions, tokens, and plan status."
      );
      return;
    }

    setLoader(true);
    try {
      const chatId = await handleUpload(files);
      console.log("Mili Hui ID", chatId)
      setLoader(false);
      await getPreviousChats();
      await getFolders();
      navigate(`/chat/chatbot/${chatId}`);
      handleClose();
    } catch (error) {
      console.error("Error uploading files:", error);
      setLoader(false);
    }
  };

  const handleFileSelect = (e, doc) => {
    if (e.target.checked) {
      setInputDocs([...inputDocs, doc]);
    } else {
      setInputDocs(
        inputDocs.filter(
          (inputDoc) => inputDoc.name !== doc.name
        )
      );
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      style={{ padding: "20px" }}
      dialogClassName="modal-lg"
    >
      {loader ? (
        <PageLoader />
      ) : (
        <>
          <Modal.Header closeButton>
            <Modal.Title>Let's Decode this Document!: ✨</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="w-100 d-flex flex-column flex-sm-row gap-4">
              <div className="d-flex w-100 w-sm-50 flex-sm-fill flex-column gap-3">
                <label className="inline-flex items-center cursor-pointer">
                  <input
                    onChange={() => setUseCustomPrompt(!useCustomPrompt)}
                    type="checkbox"
                    checked={useCustomPrompt}
                    className="sr-only peer"
                  />
                  <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue dark:peer-focus:ring-offset-blue-950 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" />
                  <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Use Custom Prompt
                  </span>
                </label>

                <div className="d-flex flex-column gap-3">
                  <label className="fw-medium">Select Use Case</label>
                  <select
                    className="form-select"
                    value={useCase}
                    onChange={handleUseCaseChange}
                  >
                    {useCases.map((useCaseObj, index) => (
                      <option key={index} value={useCaseObj.name}>
                        {useCaseObj.name}
                      </option>
                    ))}
                  </select>
                </div>

                {useCustomPrompt ? (
                  <div className="d-flex flex-column gap-3">
                    <label className="fw-medium">Enter Custom Prompt</label>
                    <textarea
                      className="form-control"
                      rows={3}
                      placeholder="Type your custom prompt here..."
                      value={customPrompt}
                      onChange={(e) => setCustomPrompt(e.target.value)}
                    />
                  </div>
                ) : (
                  <div className="d-flex flex-column gap-3">
                    <label className="fw-medium">Choose Prompt</label>
                    <select
                      className="form-select"
                      value={selectedPrompt?.id}
                      onChange={handlePromptChange}
                    >
                      {prompts.map((prompt, index) => (
                        <option key={index} value={prompt.id}>
                          {prompt.text}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                <label className="inline-flex items-center cursor-pointer">
                  <input
                    onChange={() => {
                      setFromPreviousFiles(!fromPreviousFiles);
                      setFiles([]);
                    }}
                    type="checkbox"
                    checked={fromPreviousFiles}
                    className="sr-only peer"
                  />
                  <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue dark:peer-focus:ring-offset-blue-950 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" />
                  <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Chat from previous documents
                  </span>
                </label>
              </div>

              {fromPreviousFiles ? (
                <div className="d-flex w-100 w-sm-50 flex-sm-fill flex-column gap-3">
                  <div className="d-flex flex-column gap-3">
                    <label className="fw-medium">Select Files</label>
                    <div>
                      <ul
                        className="list-group"
                        style={{ maxHeight: "240px", overflowY: "auto" }}
                      >
                        {Helpers.authUser.files?.length > 0 ? (
                          Helpers.authUser.files.map((doc, index) => (
                            <li
                              key={index}
                              className="list-group-item list-group-item-action"
                            >
                              <label>
                                <input
                                  type="checkbox"
                                  onChange={(e) => handleFileSelect(e, doc)}
                                />
                                <span className="ms-2">{doc.name}</span>
                              </label>
                            </li>
                          ))
                        ) : (
                          <p>No uploaded files.</p>
                        )}
                        <button
                          className="btn btn-primary mt-2"
                          onClick={() => handlePreviousDocsSelect(inputDocs)}
                          disabled={inputDocs.length === 0}
                        >
                          Start Chat with Selected Files
                        </button>
                      </ul>
                    </div>
                  </div>
                </div>
              ) : (
<div className="d-flex -100 w-sm-50 flex-sm-fill flex-column gap-3">
  {/* Folder Toggle */}
  <label className="inline-flex items-center cursor-pointer">
    <input
      onChange={() => {
        setShowFolders(!showFolders);
        if (selectedFolder !== null) {
          setSelectedFolder(null);
        }
      }}
      type="checkbox"
      checked={showFolders}
      className="sr-only peer"
    />
    <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue dark:peer-focus:ring-offset-blue-950 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" />
    <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
      Select Folder
    </span>
  </label>
  {showFolders && (
    <div className="d-flex flex-column gap-3">
      <select
        className="form-select"
        value={selectedFolder}
        onChange={handleFolderChange}
      >
        {folders.map((folder, index) => (
          <option key={index} value={folder.id}>
            {folder.name}
          </option>
        ))}
      </select>
    </div>
  )}

  {/* Drag-and-Drop for File Selection */}
  <div
  className={`${
    dragging ? "border border-primary bg-light" : ""
  }`}
  onDragOver={handleDragOver}
  onDragLeave={handleDragLeave}
  onDrop={handleDrop}
  style={{
    height: "230px",
    border: "2px dashed lightgrey",
    position: "relative",
  }}
>
  <label
    className="d-flex justify-content-center align-items-center w-100 h-100"
    htmlFor="fileInput"
    style={{ cursor: "pointer" }}
  >
    <input
      type="file"
      id="fileInput"
      multiple
      onChange={handleFileChange}
      style={{
        opacity: 0,
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
      }}
    />
    <div>
      {files.length === 0 ? (
        <p className="text-center">
          Drag and drop your files here or click to upload
        </p>
      ) : (
        <ul className="list-unstyled">
          {files.map((file, index) => (
            <li key={index}>{file.name}</li>
          ))}
        </ul>
      )}
    </div>
  </label>
</div>

  <div className="d-flex gap-2">
    <button
      className="btn btn-primary mt-2"
      onClick={handleUploadButton}
      disabled={files.length === 0}
    >
      Start Chat
    </button>
    {files.length > 0 && (
      <button className="btn btn-danger mt-2" onClick={clearFiles}>
        Clear Files
      </button>
    )}
  </div>
</div>

              )}
            </div>
          </Modal.Body>
        </>
      )}
    </Modal>
  );
}
