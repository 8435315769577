import React, { createContext, useContext, useState, useEffect } from 'react';
import Helpers from '../Config/Helpers';
import axios from 'axios';

const PlanContext = createContext();

export const PlanProvider = ({ children }) => {
    const [userData, setUserData] = useState(null);
    const [userTransactions, setUserTransactions] = useState([]);
    const [Transaction, setTransaction] = useState(null);
    const [TokensAvailable, setTokensAvailable] = useState(false);
    const [orgUsedTokens, setOrgUsedTokens] = useState(0);
    const [availableTokens, setAvailableTokens] = useState(0);
    const [isPlanActive, setIsPlanActive] = useState("");

    const getProfileInfo = async () => {
        try {
            const { data } = await axios.get(`${Helpers.apiUrl}user/info`, Helpers.authHeaders);

            const { user, latestTransaction, total_used_tokens } = data;
            const { transactions, org_id, no_tokens, used_tokens, is_org_owner, organization } = user;
            Helpers.setItem('user', user, true);
            console.log(user)
            setUserData(user);
            setUserTransactions(transactions);
            setTransaction(latestTransaction);
            setOrgUsedTokens(total_used_tokens);
            console.log("abe latest transaction",latestTransaction)

            if (latestTransaction) {
                const expiryDate = new Date(latestTransaction.expiry_date);
                setIsPlanActive(expiryDate < new Date() ? "Expired" : "Active");
            }



            const availableTokens = org_id ? organization.no_tokens - total_used_tokens : no_tokens - used_tokens;
            setAvailableTokens(availableTokens)
            setTokensAvailable(availableTokens >= 350);

        } catch (error) {
            console.error("Error fetching user info: ", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        getProfileInfo();
        console.log(userData);
    }, []);


    return (
        <PlanContext.Provider value={{ userTransactions, availableTokens, orgUsedTokens, TokensAvailable, Transaction, getProfileInfo, isPlanActive, userData }}>
            {children}
        </PlanContext.Provider>
    );
};

export const usePlan = () => useContext(PlanContext);
