import React, { createContext, useContext, useState, useCallback, useEffect } from "react";
import Helpers from "../Config/Helpers";
import axios from "axios";
// Create Context
const ChatsContext = createContext();

// Provider Component
export const ChatsProvider = ({ children }) => {
  // Example state to manage chat data
  const [userChats, setUserChats] = useState([]);
//   const [folders, setFolders] = useState([]);
const [selectedChat, setSelectedChat] = useState("Chats");

  const getPreviousChats = async () => {
    try {
      const userString = localStorage.getItem("user");
      if (userString) {
        const user_chats = await axios.get(
          `${Helpers.apiUrl}chat/all`,
          Helpers.authHeaders
        );
        console.log(user_chats)
        setUserChats(user_chats.data);

      } else {
        console.log("No user found in localStorage");
        setSelectedChat("Chats");
      }
    } catch (e) {
      console.log("Error: ", e);
      setSelectedChat("Chats");
    }
  };

  useEffect(() => {
    getPreviousChats();
  }, [])

  return (
    <ChatsContext.Provider value={{ selectedChat,setSelectedChat,setUserChats,userChats,getPreviousChats }}>
      {children}
    </ChatsContext.Provider>
  );
};

// Custom Hook to Use Context
export const useChatsContext = () => {
  return useContext(ChatsContext);
};
