import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate,useLocation } from "react-router-dom";
import { Accordion, ListGroup, Modal, Form,Spinner } from "react-bootstrap";
import axios from "axios";
import Helpers from "../../../Config/Helpers";
import ChatModal from "../../../Components/ChatModal";
import { ChatAppContext } from "../../../Context/AppContext";
import { usePlan } from "../../../Context/PlanContext";
import { OpenAIEmbeddings } from "@langchain/openai";
import { useApi } from "../../../Context/ApiContext";
import { useFolders } from "../../../Context/FolderContext";
import { useChatsContext } from "../../../Context/ChatsContext";
import PlanCard from "../../../Components/PlanCard";
import { createClient } from "@supabase/supabase-js";
import { RecursiveCharacterTextSplitter } from "langchain/text_splitter";
import { SupabaseVectorStore } from "@langchain/community/vectorstores/supabase";

const NavBarRedesigned = () => {
  const [noPermission, setNoPermission] = useState();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { apiSettings } = useApi();
  const sbUrl = apiSettings.supabaseUrl;
  const sbApiKey = apiSettings.supabaseApiKey;
  const [isLoading, setIsLoading] = useState(false);
  
  const { newChat,selectedDocs, setSelectedDocs } = useContext(ChatAppContext);
  const { isPlanActive, TokensAvailable, userData, getProfileInfo } = usePlan();
  const {selectedChat,setSelectedChat, userChats, setUserChats,getPreviousChats} = useChatsContext();
  const [selectedChatFiles, setSelectedChatFiles] = useState([]);
  const [activeFolderId, setActiveFolderId] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  // const [folders, setFolders] = useState([]);
  const [folderName, setFolderName] = useState("");
  const [showFolderModal, setShowFolderModal] = useState(false);
  // const [userChats, setUserChats] = useState([]);
  const {folders,setFolders,deleteFolder,getFolders} = useFolders();
  // const [selectedChat, setSelectedChat] = useState("Chats");
  const [activeChatId, setActiveChatId] = useState(null);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [isFileAccordionOpen, setIsFileAccordionOpen] = useState(false);
  const [showChatModal, setShowChatModal] = useState(false);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      getFolders();
      getPreviousChats();
    }
  }, []);

  useEffect(() => {
    if (newChat) {
      setUserChats((prev) => [...prev, newChat]);
      setSelectedChat(newChat.chat_detail);
      setActiveChatId(newChat.chat_id);
    }
  }, [newChat]);

  const estimateTokensForVectorization = (text) => {
    const tokens = text.split(/\s+/).length;
    return tokens;
  };

  const handleFileChange = (e) => {
    if (!TokensAvailable) {
      Helpers.toast("error", "Your Tokens Are Almost Finished");
      return;
    }

    const file = e.target.files[0];
    if (file) {
      setSelectedChatFiles([]);
      handleUpload(file, activeChatId,activeFolderId);
    }
  };

  // const getFolders = async () => {
  //   try {
  //     const response = await axios.get(`${Helpers.apiUrl}folders`, Helpers.authHeaders);
  //     setFolders(response.data);
  //   } catch (error) {
  //     console.error("Error fetching folders:", error);
  //   }
  // };
  const NavigateToChatPage = (chats,index) => {
    setSelectedChat(chats.title);
    setSelectedChatFiles(chats.file);
    setActiveChatId(chats.chatid);
    setActiveFolderId(chats.folder_id);
    setIsAccordionOpen(false);
    navigate(`/chat/chatbot/${chats.chatid}`);
  };

  useEffect(() => {
    // Extract chat_id from the URL (e.g., /chat/chatbot/:chat_id)
    const chatIdFromUrl = location.pathname.split('/').pop();

    // Check if we have a valid chat_id in the URL
    if (chatIdFromUrl) {
      // Find the chat with the matching chat_id
      const chat = userChats.find(chat => chat.chatid === chatIdFromUrl);

      if (chat) {
        console.log("My New Chat", chat);
        // If chat exists, set the state just like you would on click
        setSelectedChat(chat.title); // Assuming you want the first file's name
        setSelectedChatFiles(chat.file);
        setActiveChatId(chat.chatid);
        setActiveFolderId(chat.folder_id);
        setIsAccordionOpen(false);
      }
    }
  }, [location.pathname, userChats]);


  useEffect(() => {
    getPreviousChats(); // Fetch previous chats if user data exists
  }, []);
  const createFolder = async () => {
    if (!folderName) return;
    try {
      const response = await axios.post(`${Helpers.apiUrl}folders`, { name: folderName }, Helpers.authHeaders);
      setFolders((prev) => [...prev, response.data]);
      setFolderName("");
      setShowFolderModal(false);
      Helpers.toast("success", "Folder created successfully!");
    } catch (error) {
      console.error("Error creating folder:", error);
      Helpers.toast("error", "Failed to create folder.");
    }
  };

  // const deleteFolder = async (folderId) => {
  //   try {
  //     await axios.delete(`${Helpers.apiUrl}folders/${folderId}`, Helpers.authHeaders);
  //     setFolders((prev) => prev.filter((folder) => folder.id !== folderId));
  //     Helpers.toast("success", "Folder deleted successfully!");
  //   } catch (error) {
  //     console.error("Error deleting folder:", error);
  //     Helpers.toast("error", "Failed to delete folder.");
  //   }
  // };

  // const getPreviousChats = async () => {
  //   try {
  //     const response = await axios.get(`${Helpers.apiUrl}chat/all`, Helpers.authHeaders);
  //     const chats = response.data.map((datum) => ({
  //       chat_detail: datum.file_content.replace(/\n/g, " ").slice(15, 40),
  //       chat_id: datum.chatid,
  //     }));
  //     setUserChats(chats);
  //     if (chats.length > 0) {
  //       setSelectedChat(chats[0].chat_detail);
  //       setActiveChatId(chats[0].chat_id);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching chats:", error);
  //   }
  // };

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  const handleChatSelection = (chat) => {
    console.log(chat)
    setSelectedChat(chat.title);
    setActiveChatId(chat.chat_id);
    setActiveFolderId(chat.folder_id);
    setIsAccordionOpen(false);
    setIsExpanded(false);
    navigate(`/chat/chatbot/${chat.chatid}`);
  };

  const handleUpload = async (file, chatid,folder_Id) => {
    console.log(folder_Id)
    if (file && chatid) {
      setIsLoading(true);
      try {
        const formData = new FormData();
        formData.append("files[]", file);
        formData.append("chatid", chatid);
        formData.append("prompt_id", 1);
        formData.append("folderId", folder_Id);

        const response = await axios.post(
          `${Helpers.apiUrl}user/save`,
          formData,
          Helpers.authFileHeaders
        );


        if (response) {
          setSelectedChatFiles([file.name]);
          await getPreviousChats();

          const chatData = response.data.file_content;
          console.log(chatData)
          const splitter = new RecursiveCharacterTextSplitter({
            chunkSize: 500,
            chunkOverlap: 50,
            separators: ["\n\n", "\n", " ", ""],
          });
          const output = await splitter.createDocuments([chatData]);

          let totalTokensForVectorization = 0;
          output.forEach((chunk) => {
            totalTokensForVectorization += estimateTokensForVectorization(
              chunk.pageContent,
            );
            chunk.metadata = { ...chunk.metadata, contentid: response.data.content_id };
          });


          const client = createClient(sbUrl, sbApiKey);
          await Promise.all([
            SupabaseVectorStore.fromDocuments(
              output,
              new OpenAIEmbeddings({
                openAIApiKey: apiSettings.openAiApiKey,
              }),
              {
                client,
                tableName: "docsphere_documents",
              }
            ),
            axios.post(
              `${Helpers.apiUrl}updateTokens`,
              { tokens: totalTokensForVectorization },
              Helpers.authHeaders
            ),
          ]);
          Helpers.toast(
            "success",
            "File uploaded successfully to the existing chat"
          );
          await getProfileInfo();
          console.log("Vectorization complete");
        }
      } catch (error) {
        if (error.response) {
          console.log("error", error.response.data.message);
          Helpers.toast("error", error.response.data.message);
        } else {
          Helpers.toast("error", error);
          console.log("error", error);
        }
      } finally {
        const { data } = await axios.get(`${Helpers.apiUrl}chat/get/${activeChatId}`, Helpers.authHeaders);
        await getProfileInfo();
        console.log("Fetched chat data:", data);
        if (data.content_ids) {
          setSelectedDocs(data.content_ids);
        } else {
          setSelectedDocs([]);
        }
        setIsLoading(false);
      }
    } else {
      Helpers.toast("error", "Can't send without file or chatId");
    }
  };

  const logout = () => {
    Helpers.toast("success", "Logged out successfully");
    Helpers.authUser = null;
    localStorage.clear();
    navigate("/");
  };

  return (
    <div>
      <ChatModal show={showChatModal} handleClose={() => setShowChatModal(false)} />

      {/* Navbar */}
      <nav className="navbar fixed-top d-flex justify-content-between align-items-center bg-[#F1F2F6] p-2">

        <div className="d-flex justify-content-between align-items-center w-100 px-3 gap-2">
        <Link to="/user/profile" className="d-flex align-items-center">
          <img
            className="rounded-circle"
            src={Helpers.serverImage(Helpers.authUser.profile_pic)}
            alt="User Avatar"
            style={{ width: "40px", height: "40px" }}
          />
        </Link>
          <button className="btn btn-dark rounded-md" onClick={() => setShowChatModal(true)}>
            <i className="fas fa-plus"></i>
          </button>
          <button className="btn btn-dark rounded-md" onClick={() => setShowFolderModal(true)}>
            <i className="fas fa-folder-plus"></i>
          </button>
          <button className="btn btn-dark rounded-md" onClick={toggleExpand}>
            {isExpanded ? <i className="fas fa-compress"></i> : <i className="fas fa-expand"></i>}
          </button>
          <button className="btn btn-dark rounded-md" onClick={logout}>
            <i className="fas fa-power-off"></i>
          </button>
        </div>
      </nav>

      {isExpanded && (
        <div className="expanded-navbar bg-white p-3 rounded-md mt-5">
          {/* Folders */}
          <h6 className="text-left p-2 font-semibold">Your Folders</h6>
          <Accordion defaultActiveKey="1">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Folders</Accordion.Header>
                <Accordion.Body>
                  {folders.map((folder, index) => (
                    <Accordion
                      key={folder.id}
                      defaultActiveKey="1"
                    >
                      <Accordion.Item eventKey={index.toString()}>
                        <Accordion.Header className=" w-full d-flex align-items-center justify-between">
                          <span>{folder.name}</span>
                          <button
                            className="btn btn-danger btn-sm ms-2"
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent accordion toggle
                              deleteFolder(folder.id);
                            }}
                          >
                            X
                          </button>
                        </Accordion.Header>
                        <Accordion.Body>
                          {/* List files inside the folder */}
                          {folder.files && folder.files.length > 0 ? (
                            <ListGroup variant="flush">
                              {folder.files.map((file, fileIndex) => (
                                <ListGroup.Item
                                  key={fileIndex}
                                  className="text-truncate"
                                >
                                  {file.name}
                                </ListGroup.Item>
                              ))}
                            </ListGroup>
                          ) : (
                            <p>No files</p>
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

          {/* Chats */}
          <h6 className="text-left p-2 font-semibold">Your Chats</h6>
          <Accordion activeKey={isAccordionOpen ? "0" : null}>
            <Accordion.Item eventKey="0">
              <Accordion.Header onClick={() => setIsAccordionOpen(!isAccordionOpen)}>
                {selectedChat}
              </Accordion.Header>
              <Accordion.Body className="scrollbar-thin" style={{ maxHeight: "300px", overflowY: "auto" }}>
                {userChats.map((chat, index) => (
                  <ListGroup.Item key={index} onClick={() => handleChatSelection(chat)}>
                    {chat.title}
                  </ListGroup.Item>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          {/* Uploaded Files */}
          <h6 className="text-left p-2 font-semibold">Uploaded Files</h6>
          <Accordion activeKey={isFileAccordionOpen ? "1" : null}>
            <Accordion.Item eventKey="1">
              <Accordion.Header onClick={() => setIsFileAccordionOpen(!isFileAccordionOpen)}>
                Uploaded Files
              </Accordion.Header>
              <Accordion.Body
                className="scrollbar-thin"
                style={{ maxHeight: "300px", overflowY: "auto" }}
              >
                {Helpers.authUser.files?.length > 0 ? (
                  Helpers.authUser.files.map((file, index) => (
                    <ListGroup.Item key={index}>
                      {file.name}
                    </ListGroup.Item>
                  ))
                ) : (
                  <p>No files uploaded</p>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <div
            className={`position-relative flex justify-evenly ${
              isPlanActive === "Expired" || noPermission
                ? "pointer-events-none opacity-50"
                : ""
            }`}
          >
            <input
              type="file"
              id="file-upload"
              className="form-control d-none"
              onChange={handleFileChange}
              disabled={isLoading || !activeChatId || noPermission}
            />

            <label
              htmlFor="file-upload"
              className={`btn btn-dark ${
                isCollapsed ? "text-center w-auto" : "w-100"
              }`}
            >
              {isLoading ? (
                <>
                  <Spinner animation="border" size="sm" className="me-2" />
                  Uploading...
                </>
              ) : isCollapsed ? (
                <i className="fal fa-file-upload"></i>
              ) : (
                "Choose File"
              )}
            </label>
          </div>
          {/* Plan Card */}
          <div className="mt-4">
            {userData?.is_org_owner && <PlanCard />}
          </div>
        </div>
      )}

      {/* Folder Modal */}
      <Modal show={showFolderModal} onHide={() => setShowFolderModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create Folder</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Folder Name</Form.Label>
              <Form.Control
                type="text"
                value={folderName}
                onChange={(e) => setFolderName(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => setShowFolderModal(false)}>
            Close
          </button>
          <button className="btn btn-primary" onClick={createFolder}>
            Create
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default NavBarRedesigned;
