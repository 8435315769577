import axios from "axios";
import { useEffect, useState } from "react";
import Helpers from "../../Config/Helpers";
import TextInput from "../../Components/Input";
import useTitle from "../../Hooks/useTitle";
import SearchHeader from "../../Components/SearchHeader";
import Pagination from "../../Components/Pagination";

const TokenPackages = () => {
  useTitle("Token Packages");

  const defaultTokenPackage = {
    package_name: "",
    no_tokens: "",
    price: "",
  };

  const [tokenPackages, setTokenPackages] = useState([]);
  const [orgData, setOrgData] = useState([]);
  const [tokenPackageData, setTokenPackageData] = useState(defaultTokenPackage);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showAddTokenPackage, setShowAddTokenPackage] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedTokenPackage, setSelectedTokenPackage] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const saveTokenPackage = () => {
    setIsLoading(true);
    const url = isEditing
      ? `${Helpers.apiUrl}token-packages/${tokenPackageData.id}`
      : `${Helpers.apiUrl}token-packages`; 

    const method = isEditing ? 'put' : 'post'; 

    axios[method](url, tokenPackageData, Helpers.authHeaders)
      .then((response) => {
        if (isEditing) {
          setIsEditing(false);
        }
        allTokenPackages();
        Helpers.toast("success", response.data.message);
        setShowAddTokenPackage(false);
        setIsLoading(false);
        handleCancel();
      })
      .catch((error) => {
        Helpers.toast("error", error.response?.data?.message || "Error occurred");
        setErrors(error.response?.data?.errors || {});
        setIsLoading(false);
      });
  };

  const allTokenPackages = () => {
    axios
      .get(`${Helpers.apiUrl}token-packages`, Helpers.authHeaders)
      .then((response) => {
        let paginatedData = Helpers.paginate(response.data);
        setTokenPackages(paginatedData);
        setOrgData(response.data);
      });
  };

  const editTokenPackage = (pkg) => {
    let editTokenPackage = {
      package_name: pkg.package_name,
      no_tokens: pkg.no_tokens,
      price: pkg.price,
      id: pkg.id
    };
    setTokenPackageData(editTokenPackage);
    setShowAddTokenPackage(true);
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setTokenPackageData(defaultTokenPackage);
    setShowAddTokenPackage(false);
  };

  const initDelete = (id) => {
    setSelectedTokenPackage(id);
  };

  const cancelDelete = () => {
    setSelectedTokenPackage(0);
  };

  const deleteTokenPackage = (id) => {
    setIsDeleting(true);
    axios
      .delete(`${Helpers.apiUrl}token-packages/${id}`, Helpers.authHeaders)
      .then((response) => {
        allTokenPackages();
        setSelectedTokenPackage(0);
        Helpers.toast("success", response.data.message);
        setIsDeleting(false);
      });
  };

  useEffect(() => {
    allTokenPackages();
  }, []);

  return (
    <div className="nk-content">
      <div className="container-xl">
        <div className="nk-content-inner">
          <div className="nk-content-body">
            <div className="nk-block-head nk-page-head">
              <div className="nk-block-head-between">
                <div className="nk-block-head-content">
                  <h2 className="display-6">Token Packages</h2>
                  <p>Manage token packages for users</p>
                </div>
                {!showAddTokenPackage && (
                  <button
                    className="btn btn-primary"
                    onClick={() => setShowAddTokenPackage(true)}
                  >
                    <em className="icon ni ni-plus"></em> Add New Token Package
                  </button>
                )}
              </div>
            </div>
            {!showAddTokenPackage && (
              <div className="nk-block">
                <SearchHeader
                  isPaginated={true}
                  title={"All Token Packages"}
                  orgData={orgData}
                  setData={setTokenPackages}
                  columns={["package_name", "no_tokens", "price"]}
                />
                <div className="card shadow-none">
                  <div className="card-body">
                    <div className="row g-3 gx-gs">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Sr. #</th>
                            <th>Name</th>
                            <th>Tokens</th>
                            <th>Price</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {tokenPackages.length === 0 && (
                            <tr>
                              <td colSpan={10}>No records found...</td>
                            </tr>
                          )}
                          {tokenPackages.length > 0 &&
                            tokenPackages[currentPage].map((pkg, index) => {
                              return (
                                <tr key={pkg.id}>
                                  <td>{index + 1}</td>
                                  <td>{pkg.package_name}</td>
                                  <td>{pkg.no_tokens}</td>
                                  <td>{pkg.price}</td>
                                  <td className="tb-col-end">
                                    {selectedTokenPackage === pkg.id ? (
                                      <div>
                                        <button
                                          onClick={() => deleteTokenPackage(pkg.id)}
                                          disabled={isDeleting}
                                          className="btn btn-outline-danger btn-sm ml5"
                                        >
                                          <em className="icon ni ni-check"></em>
                                          <span className="ml5">
                                            {isDeleting ? "Deleting..." : "Yes, Delete"}
                                          </span>
                                        </button>
                                        <button
                                          onClick={cancelDelete}
                                          className="btn btn-outline-primary btn-sm ml5"
                                        >
                                          <em className="icon ni ni-cross"></em>
                                          <span className="ml5">Cancel</span>
                                        </button>
                                      </div>
                                    ) : (
                                      <div>
                                        <div className="d-flex flex-row">
                                          <button
                                            onClick={() => editTokenPackage(pkg)}
                                            className="btn btn-outline-primary btn-sm flex-grow-1 ml5"
                                          >
                                            <em className="icon ni ni-edit"></em>
                                            <span className="ml5">Edit</span>
                                          </button>
                                          <button
                                            onClick={() => initDelete(pkg.id)}
                                            className="btn btn-outline-danger btn-sm flex-grow-1 ml5"
                                          >
                                            <em className="icon ni ni-trash"></em>
                                            <span className="ml5">Delete</span>
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                      {tokenPackages.length > 0 && (
                        <Pagination
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                          data={tokenPackages}
                          orgData={orgData}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {showAddTokenPackage && (
              <div className="nk-block">
                <div className="nk-block-head nk-block-head-sm">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title">Add New Token Package</h3>
                  </div>
                </div>
                <div className="card shadow-none">
                  <div className="card-body">
                    <div className="row g-3 gx-gs">
                      <TextInput
                        label={"Name"}
                        error={errors.package_name}
                        value={tokenPackageData.package_name}
                        onChange={(e) =>
                          setTokenPackageData({
                            ...tokenPackageData,
                            package_name: e.target.value,
                          })
                        }
                      />
                      <TextInput
                        label={"Tokens"}
                        error={errors.no_tokens}
                        value={tokenPackageData.no_tokens}
                        onChange={(e) =>
                          setTokenPackageData({
                            ...tokenPackageData,
                            no_tokens: e.target.value,
                          })
                        }
                      />
                      <TextInput
                        label={"Price"}
                        error={errors.price}
                        value={tokenPackageData.price}
                        onChange={(e) =>
                          setTokenPackageData({
                            ...tokenPackageData,
                            price: e.target.value,
                          })
                        }
                      />
                      <div className="col-md-12 d-flex">
                        <button
                          className="btn btn-primary"
                          disabled={isLoading}
                          onClick={saveTokenPackage}
                        >
                          {isLoading
                            ? "Saving..."
                            : isEditing
                            ? "Save Package"
                            : "Save & Continue"}
                        </button>
                        <button
                          className="btn btn-outline-danger ml10"
                          onClick={handleCancel}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TokenPackages;
