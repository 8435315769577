import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { usePlan } from '../Context/PlanContext';

const PlanCard = ({ toggler }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { Transaction, isPlanActive } = usePlan();
  
  const toggleDetails = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <div className={`w-full  max-w-sm mx-auto bg-white border border-gray-200 rounded-lg shadow-sm`}>
      <div className="px-4 py-2 border-b border-gray-200">
        <div onClick={toggleDetails} className="flex items-center justify-between">
          <h5 className="text-md cursor-pointer font-semibold text-gray-800">{Transaction && Transaction.package
            ? `${Transaction.package.package_name} Plan`
            : "Free Plan"}</h5>
          <button className="text-sm  hover:text-blue-600 transition duration-150 focus:outline-none">
            <i className={`fas ${isExpanded ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
          </button>
        </div>
      </div>

      <div className={`overflow-hidden transition-max-height duration-500 ease-in-out ${isExpanded ? 'max-h-40' : 'max-h-0'}`}>
        <div className="px-4 py-2">
          <p className="text-sm text-gray-600">Expiry: {Transaction && Transaction.expiry_date ? Transaction.expiry_date : 'N / A'}</p>
        </div>

        <div className="px-4 py-2 bg-gray-50 rounded-b-lg">
          <p className="text-sm font-semibold">Status: {isPlanActive ? isPlanActive : 'N / A'}</p>
          <h6 className="text-left pt-2" onClick={(event) => {
            event.preventDefault(); setIsExpanded(false); if (toggler) {
              toggler();
            }
          }}>
            <Link to="/user/plans" className=" hover:text-gray-800">
              See All Plans
            </Link>
          </h6>
        </div>
      </div>
    </div>
  );
};

export default PlanCard;
