import React, { createContext, useContext, useState, useEffect } from "react";
import Helpers from "../Config/Helpers";
import axios from "axios";

const FolderContext = createContext();

export const FolderProvider = ({ children }) => {
  const [folders, setFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [showFolders, setShowFolders] = useState(false);


  const getFolders = async () => {
    try {
      const response = await axios.get(
        `${Helpers.apiUrl}folders`,
        Helpers.authHeaders
      );
      console.log(response.data);
      setFolders(response.data);
  
      // Use the fetched data directly to set the selected folder
    //   if (response.data.length > 0 && showFolders) {
    //     setSelectedFolder(response.data[0].id);
    // } else{
    //       setSelectedFolder(null);
    //   }
    } catch (error) {
      console.error("Error fetching folders:", error);
    }
  };
  useEffect(() => {
    if (showFolders && folders.length > 0) {
      setSelectedFolder(folders[0].id);
    } else {
      setSelectedFolder(null); // Reset folder selection when hiding
    }
  }, [showFolders, folders]);

  // Create a new folder
//   const createFolder = async (folderName) => {
//     if (!folderName) return;
//     try {
//       const response = await axios.post(
//         `${Helpers.apiUrl}folders`,
//         { name: folderName },
//         Helpers.authHeaders
//       );
//       setFolders((prev) => [...prev, response.data]);
//       Helpers.toast("success", "Folder created successfully!");
//     } catch (error) {
//       console.error("Error creating folder:", error);
//       Helpers.toast("error", "Failed to create folder.");
//     }
//   };

  // Delete a folder
  const deleteFolder = async (folderId) => {
    try {
      await axios.delete(
        `${Helpers.apiUrl}folders/${folderId}`,
        Helpers.authHeaders
      );
      setFolders((prev) => prev.filter((folder) => folder.id !== folderId));
      Helpers.toast("success", "Folder deleted successfully!");
    } catch (error) {
      console.error("Error deleting folder:", error);
      Helpers.toast("error", "Failed to delete folder.");
    }
  };

  useEffect(() => {
    getFolders();

  }, []);

  return (
    <FolderContext.Provider
      value={{
        folders,
        setFolders,
        getFolders,
        selectedFolder,
        showFolders,
        setShowFolders,
        setSelectedFolder,
        
        deleteFolder,
      }}
    >
      {children}
    </FolderContext.Provider>
  );
};

export const useFolders = () => useContext(FolderContext);
