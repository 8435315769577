import { useEffect, useState } from 'react';
import { createClient } from '@supabase/supabase-js';
import { SupabaseVectorStore } from "@langchain/community/vectorstores/supabase";
import { OpenAIEmbeddings } from "@langchain/openai";

import { useApi } from '../Context/ApiContext';

export const useRetriever = (selectedDocs = []) => {
  const { apiSettings } = useApi();
  const [retriever, setRetriever] = useState(null);
  useEffect(() => {

    console.log("useRetriever called with:", {
      apiSettings,
      selectedDocs,
    });

    if (!apiSettings.isLoading && apiSettings.openAiApiKey && apiSettings.supabaseApiKey && apiSettings.supabaseUrl && selectedDocs?.length > 0) {
      console.log("Initializing embeddings with supabaseUrl:", apiSettings.supabaseUrl);
      
      try {
        const embeddings = new OpenAIEmbeddings({
          openAIApiKey: apiSettings.openAiApiKey
        });
        
        const client = createClient(apiSettings.supabaseUrl, apiSettings.supabaseApiKey);
        console.log(selectedDocs)
        // const contentids = selectedDocs.map(doc => doc?.id ?? doc);
        const contentids = selectedDocs;
        
        const vectorStore = new SupabaseVectorStore(embeddings, {
          client,
          tableName: 'docsphere_documents',
          queryName: 'match_docsphere_documents'
        });

        const customRetriever = {
          getRelevantDocuments: async (query) => {
            const queryEmbedding = await embeddings.embedQuery(query);
            console.log(queryEmbedding)
            console.log(contentids)
            const { data: documents } = await client.rpc('match_docsphere_documents', {
              query_embedding: queryEmbedding,
              contentids: contentids,
            });

            if (documents) {
              console.log("Retrieved documents:", documents);
              return documents;
            } else {
              console.error("No documents found for the query.");
              return [];
            }
          }
        };
        
        setRetriever(customRetriever);
      } catch (error) {
        console.error("Error initializing retriever:", error);
      }
    }
  }, [apiSettings, selectedDocs]);

  return retriever;
};
