import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { PlanProvider } from "./Context/PlanContext";
import { ApiProvider } from "./Context/ApiContext";
import { ChatsProvider } from "./Context/ChatsContext";
import { FolderProvider } from "./Context/FolderContext";

// import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
<PlanProvider>
    <ApiProvider>
        <ChatsProvider>
            <FolderProvider>
                <App />
            </FolderProvider>
        </ChatsProvider>
    </ApiProvider>
</PlanProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
